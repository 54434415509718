/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
   * Default color scale
   */

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
  fill: #8cc665;
}

/*
   * Github color scale
   */

.react-calendar-heatmap .color-github-0 {
  fill: #eeeeee;
}
.react-calendar-heatmap .color-github-1 {
  fill: #d6e685;
}
.react-calendar-heatmap .color-github-2 {
  fill: #8cc665;
}
.react-calendar-heatmap .color-github-3 {
  fill: #44a340;
}
.react-calendar-heatmap .color-github-4 {
  fill: #1e6823;
}

/*
   * Gitlab color scale
   */

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-gitlab-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
  fill: #254e77;
}

.react-calendar-heatmap .color-scale-1 {
  fill: #d6e685;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #d92f2f;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #ffcfcf;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #216100;
}
.react-calendar-heatmap .color-scale-red {
  fill: #7a0b0b;
}

@media only screen and (max-width: 600px) {
  .react-calendar-heatmap text {
    font-size: 5px;
    fill: #aaa;
  }
}
